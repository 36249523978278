import {UseGetData} from "~/composables/getData.js";
import { keys } from 'idb-keyval';
import { UseKeysIDB } from "~/composables/keysIDB.js";
import { UseCreateTicketsData } from "~/composables/IDB/tickets/createTicketsData.js";
import {useUserStore} from "~/stores/user.js";
import {useGeneralApp} from "~/stores/generalApp.js";

export const useFillTicketLists = async () => {
    const userStore = useUserStore()
    const generalApp = useGeneralApp()
    try {
        let ticketsKeys = await keys(UseKeysIDB().ticketsData)
        let pendingKeys = await keys(UseKeysIDB().pendingData)
        let tempArchive = []
        let tempList = []
        if (ticketsKeys.length > 0) {
            for (let key of ticketsKeys) {
                let data = await UseGetData('tickets', key)
                let media_attachments = []
                let newTicket = {
                    id: data.id,
                    number: data.number,
                    status: data.status,
                    date: data.date,
                    user_id: data.user_id,
                    emergency_type: data.emergency_type,
                    description: data.description,
                    danger_category: data.danger_category,
                    danger_zone: data.danger_zone,
                    danger_sub_zone: data.danger_sub_zone,
                    subject: data.subject,
                    media_attachments: media_attachments,
                    comment: '',
                    notifications: data.notifications,
                    hasAttachments: data.hasAttachments
                }
                newTicket.notifications ? tempList.unshift(newTicket) : tempArchive.unshift(newTicket)
            }
        }
        if (pendingKeys.length > 0) {
            for (let key of pendingKeys) {
                if (key.split('/')[0] === 'new_ticket') {
                    let newTicket = await UseGetData('pending', key)
                    newTicket.data.notifications ? tempList.unshift(newTicket.data) : tempArchive.unshift(newTicket.data)
                }
            }
        }
        userStore.testUser.user_tickets = tempList.sort(function(a, b) {
            return new Date(b.date) - new Date(a.date)
        })
        userStore.user_tickets_archive = tempArchive.sort(function(a, b) {
            return new Date(b.date) - new Date(a.date)
        })
        if (generalApp.archiveActive) {
            generalApp.currentListOfTickets = userStore.user_tickets_archive
        } else {
            generalApp.currentListOfTickets = userStore.testUser.user_tickets
        }
    } catch (err) {
        console.log(err, 'error during filling ticket lists')
    }
}