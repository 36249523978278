
export const useCheckPermissions = async () => {
    const permissionsList = [
        "microphone",
        "notifications",
    ];
    let deniedPermissions = []
    async function processPermissions() {
        for (const permission of permissionsList) {
            const result = await getPermission(permission);
            if (result.state === 'denied') {
                deniedPermissions.push(result)
            }
        }
    }
    async function getPermission(permission) {
        try {
            const result = await navigator.permissions.query({ name: permission });
            return {
                name: permission,
                state: result.state,
            };
        } catch (error) {
            return {
                name: permission,
                state: 'not-supported',
            };
        }
    }
    await processPermissions()
    return deniedPermissions;
}