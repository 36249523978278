<script setup>
import {useGetUserInfo} from "~/composables/general/getUserInfo.js";

const props = defineProps({
  error: Object,
  required: true,
});
const {error} = props
console.log(error)
const handleError = async () => {
  try {
    await useGetUserInfo()
    localStorage.removeItem("generalApp");
    localStorage.removeItem("user");
    await clearError({redirect: '/'});
  } catch (err) {
    console.log(err, 'error when resolving error LOL')
  }
}
</script>

<template>
  <div id="errorPage" class="w-full overflow-auto">
    <img src="/img/bg-start.png"
         class="absolute z-[0] opacity-[30%] w-full h-full"
         alt="">
    <div class="absolute h-screen w-screen z-[1] bg-black opacity-80"></div>
    <div class="relative mx-auto max-w-[500px] z-[2] flex flex-col items-center justify-center overflow-hidden h-screen p-4">
      <div class="w-full h-full flex gap-[3rem] flex-col justify-center items-center">
        <div class="flex flex-col justify-start mb-[10px]">
          <h2 class="text-center w-full text-cement-orange text-[22px] font-bold">ОХ!  Возникла проблема.</h2>
          <p class="text-center w-full text-cement-orange text-[14px]">Не волнуйтесь, мы уже знаем о ней и скоро все наладится.</p>
        </div>
        <img
            class=""
            width="111px"
            height="126px"
            src="/img/error-tiger.jpeg"
            alt="">
        <div class="flex flex-col justify-center items-center mt-[50px]">
          <h2 class="text-center w-full text-cement-orange text-[22px] font-bold">Чуть-чуть терпения, пожалуйста.</h2>
          <button class="w-[290px] h-[52px] mt-[40px] rounded bg-cement-orange-non-active text-white p-4"
                  @click="handleError">
            Вернуться к работе
          </button>
        </div>
      </div>
    </div>
  </div>

</template>
