import {set} from 'idb-keyval'
import {UseKeysIDB} from "~/composables/keysIDB.js";

export const UseCreateTicketsData = async (data) => {
    for (const ticket of data) {
        set( `ticket-${ticket.id}`,  {
                id: ticket.id,
                number: ticket.number,
                status: ticket.status.name,
                date: ticket.created_at,
                hasAttachments: ticket.hasAttachments,
                user_id: ticket.author.id,
                emergency_type: ticket.priority.name,
                description: ticket.description,
                danger_category: ticket.risk.id,
                danger_zone: ticket.workspace.id,
                danger_sub_zone: ticket.workspace_section.id,
                media_attachments: [],
                comment: '',
                notifications: !ticket.archived,
                subject: ticket.subject
            }, UseKeysIDB().ticketsData)
            .catch((e) => console.log(e));
    }
}