// stores/counter.js
import { defineStore } from 'pinia'

export const useUserStore = defineStore('user', {
  state: () => ({
    token: '',
    testUser: {
      id: "",
      login: '',
      role: '',
      place: '',
      simplified_places: false,
      user_tickets: [],
      assigned_tickets: [],
      verified: false
    },
    newTicket: {
      id: '',
      number: '',
      status: '',
      date: '',
      user_id: '',
      emergency_type: '',
      description: '',
      danger_category: '',
      danger_zone: '',
      danger_sub_zone: '',
      media_attachments: [],
      comment: '',
      notifications: true,
    },
    newComment: {
      text: '',
      media: []
    },
    newAudio: {
      file: null,
      stream: null,
      mediaRecorder: null,
      recordChunk: [],
      url: ''
    },
    user_tickets_archive: [],
    userLogin: '',
    userPassword: '',
    userConfirmCode: '',
    lastTicketID: 0,
    timer_for_ticket_done: 0,
    new_ticket_category: ref(),
    new_ticket_zone: ref(),
    new_ticket_sub_zone: ref(),
    new_ticket_description: ref(),
    new_ticket_emergency_type: ref(),
  }),

  actions: {

  },

  persist: {
    storage: persistedState.localStorage,
  },
})