import {usePendingHandle} from "~/composables/pendingHandle/pendingHandle.js";
import {useGeneralApp} from "~/stores/generalApp.js";


export const useOnlineCheck = async () => {
    const generalApp = useGeneralApp()
    async function isOnline()
    {
        try
        {
            if (!self.navigator.onLine)
                return false;
            const request = new URL(self.location.origin);
            request.searchParams.set('rand', Date.now().toString());
            const response = await fetch(request.toString(), { method: 'HEAD' });
            return response.ok;
        }
        catch
        {
            return false;
        }
    }
    if (await isOnline()) {
        console.log('online');
        await usePendingHandle();
        generalApp.isOffline = false
    } else {
        console.log("You are off-line");
        generalApp.isOffline = true
    }
}