import {useNotifications} from "~/stores/notifications.js";
import {useCheckPermissions} from "~/composables/general/checkPermissions.js";


export const useGetMicPermission = async () => {
    const notifications = useNotifications()
    try {
        if (navigator.userAgent.indexOf("Firefox") === -1) {
            let micPermission = await navigator.permissions.query({name: 'microphone'})
            console.log(micPermission.state)
            if (micPermission.state !== 'granted') {
                await navigator.mediaDevices.getUserMedia({ audio: true })
                    .then((stream) => {
                        stream.getTracks().forEach((track) => {
                            if (track.readyState === 'live' && track.kind === 'audio') {
                                track.stop();
                            }
                        });
                    })
            }
        }
    } catch (err) {
        console.log(err.message)
        switch (err.message) {
            case 'Permission denied': {
                notifications.permissionDeniedNotification = true
                notifications.permissionDeniedList = notifications.permissionDeniedList.includes('микрофон')
                    ?notifications.permissionDeniedList
                    :notifications.permissionDeniedList + 'микрофон '
            }
        }
    }

}