import {set} from 'idb-keyval'
import {UseKeysIDB} from "~/composables/keysIDB.js";


export const useCreateAssignmentsData = async (data) => {
    for (let ticket of data) {
        set( `assignment-${ticket.id}`,  {
            id: ticket.id,
            number: ticket.number,
            status: ticket.status.name,
            date: ticket.created_at,
            hasAttachments: ticket.hasAttachments,
            user_id: ticket.author.id,
            emergency_type: ticket.priority.name,
            description: ticket.description,
            danger_category: ticket.risk.id,
            danger_zone: ticket.workspace.id,
            danger_sub_zone: ticket.workspace_section.id,
            media_attachments: [],
            comment: '',
            notifications: true,
            subject: ticket.subject
        }, UseKeysIDB().assignmentsData)
            .catch((e) => console.log(e));
    }
}