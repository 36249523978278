import { default as _91id_93RJNLBkjQ8mMeta } from "/app/pages/assignments/[id].vue?macro=true";
import { default as indexgM9oiSOU6UMeta } from "/app/pages/assignments/index.vue?macro=true";
import { default as confirmiabBgwNTSOMeta } from "/app/pages/confirm.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as create_45new4aOQZSZjx1Meta } from "/app/pages/my-tickets/create-new.vue?macro=true";
import { default as indexsnxJQBey6ZMeta } from "/app/pages/my-tickets/index.vue?macro=true";
import { default as _91id_939M1axgGtdPMeta } from "/app/pages/my-tickets/ticket/[id].vue?macro=true";
import { default as createHtqFgVuvzUMeta } from "/app/pages/offline/create.vue?macro=true";
import { default as mainVT0jLLBQwnMeta } from "/app/pages/offline/main.vue?macro=true";
import { default as sign_45inMH4whhaZtqMeta } from "/app/pages/sign-in.vue?macro=true";
import { default as _91id_93RVBZU44zsvMeta } from "/app/pages/user/[id].vue?macro=true";
import { default as appearanceZt3yvtD7l3Meta } from "/app/pages/user/appearance.vue?macro=true";
import { default as notificationskdBIBXNJrYMeta } from "/app/pages/user/notifications.vue?macro=true";
import { default as profile_45settingsj2h5pTcM5kMeta } from "/app/pages/user/profile-settings.vue?macro=true";
export default [
  {
    name: _91id_93RJNLBkjQ8mMeta?.name ?? "assignments-id",
    path: _91id_93RJNLBkjQ8mMeta?.path ?? "/assignments/:id()",
    meta: _91id_93RJNLBkjQ8mMeta || {},
    alias: _91id_93RJNLBkjQ8mMeta?.alias || [],
    redirect: _91id_93RJNLBkjQ8mMeta?.redirect,
    component: () => import("/app/pages/assignments/[id].vue").then(m => m.default || m)
  },
  {
    name: indexgM9oiSOU6UMeta?.name ?? "assignments",
    path: indexgM9oiSOU6UMeta?.path ?? "/assignments",
    meta: indexgM9oiSOU6UMeta || {},
    alias: indexgM9oiSOU6UMeta?.alias || [],
    redirect: indexgM9oiSOU6UMeta?.redirect,
    component: () => import("/app/pages/assignments/index.vue").then(m => m.default || m)
  },
  {
    name: confirmiabBgwNTSOMeta?.name ?? "confirm",
    path: confirmiabBgwNTSOMeta?.path ?? "/confirm",
    meta: confirmiabBgwNTSOMeta || {},
    alias: confirmiabBgwNTSOMeta?.alias || [],
    redirect: confirmiabBgwNTSOMeta?.redirect,
    component: () => import("/app/pages/confirm.vue").then(m => m.default || m)
  },
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index",
    path: indexN6pT4Un8hYMeta?.path ?? "/",
    meta: indexN6pT4Un8hYMeta || {},
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: create_45new4aOQZSZjx1Meta?.name ?? "my-tickets-create-new",
    path: create_45new4aOQZSZjx1Meta?.path ?? "/my-tickets/create-new",
    meta: create_45new4aOQZSZjx1Meta || {},
    alias: create_45new4aOQZSZjx1Meta?.alias || [],
    redirect: create_45new4aOQZSZjx1Meta?.redirect,
    component: () => import("/app/pages/my-tickets/create-new.vue").then(m => m.default || m)
  },
  {
    name: indexsnxJQBey6ZMeta?.name ?? "my-tickets",
    path: indexsnxJQBey6ZMeta?.path ?? "/my-tickets",
    meta: indexsnxJQBey6ZMeta || {},
    alias: indexsnxJQBey6ZMeta?.alias || [],
    redirect: indexsnxJQBey6ZMeta?.redirect,
    component: () => import("/app/pages/my-tickets/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_939M1axgGtdPMeta?.name ?? "my-tickets-ticket-id",
    path: _91id_939M1axgGtdPMeta?.path ?? "/my-tickets/ticket/:id()",
    meta: _91id_939M1axgGtdPMeta || {},
    alias: _91id_939M1axgGtdPMeta?.alias || [],
    redirect: _91id_939M1axgGtdPMeta?.redirect,
    component: () => import("/app/pages/my-tickets/ticket/[id].vue").then(m => m.default || m)
  },
  {
    name: createHtqFgVuvzUMeta?.name ?? "offline-create",
    path: createHtqFgVuvzUMeta?.path ?? "/offline/create",
    meta: createHtqFgVuvzUMeta || {},
    alias: createHtqFgVuvzUMeta?.alias || [],
    redirect: createHtqFgVuvzUMeta?.redirect,
    component: () => import("/app/pages/offline/create.vue").then(m => m.default || m)
  },
  {
    name: mainVT0jLLBQwnMeta?.name ?? "offline-main",
    path: mainVT0jLLBQwnMeta?.path ?? "/offline/main",
    meta: mainVT0jLLBQwnMeta || {},
    alias: mainVT0jLLBQwnMeta?.alias || [],
    redirect: mainVT0jLLBQwnMeta?.redirect,
    component: () => import("/app/pages/offline/main.vue").then(m => m.default || m)
  },
  {
    name: sign_45inMH4whhaZtqMeta?.name ?? "sign-in",
    path: sign_45inMH4whhaZtqMeta?.path ?? "/sign-in",
    meta: sign_45inMH4whhaZtqMeta || {},
    alias: sign_45inMH4whhaZtqMeta?.alias || [],
    redirect: sign_45inMH4whhaZtqMeta?.redirect,
    component: () => import("/app/pages/sign-in.vue").then(m => m.default || m)
  },
  {
    name: _91id_93RVBZU44zsvMeta?.name ?? "user-id",
    path: _91id_93RVBZU44zsvMeta?.path ?? "/user/:id()",
    meta: _91id_93RVBZU44zsvMeta || {},
    alias: _91id_93RVBZU44zsvMeta?.alias || [],
    redirect: _91id_93RVBZU44zsvMeta?.redirect,
    component: () => import("/app/pages/user/[id].vue").then(m => m.default || m)
  },
  {
    name: appearanceZt3yvtD7l3Meta?.name ?? "user-appearance",
    path: appearanceZt3yvtD7l3Meta?.path ?? "/user/appearance",
    meta: appearanceZt3yvtD7l3Meta || {},
    alias: appearanceZt3yvtD7l3Meta?.alias || [],
    redirect: appearanceZt3yvtD7l3Meta?.redirect,
    component: () => import("/app/pages/user/appearance.vue").then(m => m.default || m)
  },
  {
    name: notificationskdBIBXNJrYMeta?.name ?? "user-notifications",
    path: notificationskdBIBXNJrYMeta?.path ?? "/user/notifications",
    meta: notificationskdBIBXNJrYMeta || {},
    alias: notificationskdBIBXNJrYMeta?.alias || [],
    redirect: notificationskdBIBXNJrYMeta?.redirect,
    component: () => import("/app/pages/user/notifications.vue").then(m => m.default || m)
  },
  {
    name: profile_45settingsj2h5pTcM5kMeta?.name ?? "user-profile-settings",
    path: profile_45settingsj2h5pTcM5kMeta?.path ?? "/user/profile-settings",
    meta: profile_45settingsj2h5pTcM5kMeta || {},
    alias: profile_45settingsj2h5pTcM5kMeta?.alias || [],
    redirect: profile_45settingsj2h5pTcM5kMeta?.redirect,
    component: () => import("/app/pages/user/profile-settings.vue").then(m => m.default || m)
  }
]