import {UseUpdateData} from "~/composables/updateData.js";
import {UseGetData} from "~/composables/getData.js";
import {useUserStore} from "~/stores/user.js";
import {useNotifications} from "~/stores/notifications.js";
import {useUserNameNotification} from "~/composables/notifications/userNameNotification.js";


export const useUpdateUserLogin = async (res) => {
    const userStore = useUserStore()
    const notifications = useNotifications()
    const route = useRoute()
    await UseUpdateData('user', 'login', res.Data.user.name).then(async() => {
        userStore.testUser.login = await UseGetData('user', 'login')
        if ((userStore.testUser.login.split(' ')[1].length === 10)&&(userStore.testUser.login.split(' ')[0] === 'Пользователь')) {
            userStore.testUser.login = 'Пользователь'
            userStore.testUser.verified = false
            if (route.path === '/') {
                notifications.setUserName = useUserNameNotification()
            }
        } else {
            userStore.testUser.verified = true
            notifications.setUserName = false
        }
    })
}