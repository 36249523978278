import {UseUpdateData} from "~/composables/updateData.js";
import {UseDeleteData} from "~/composables/deleteData.js";
import {UseGetData} from "~/composables/getData.js";
import {useGeneralApp} from "~/stores/generalApp.js";
import {useUserStore} from "~/stores/user.js";

export const useHandleLogout = async () => {
    try {
        const generalApp = useGeneralApp()
        const userStore = useUserStore()
        await UseUpdateData('app','isAuthorized', false).then(async () => {
            await UseDeleteData('user')
            await UseDeleteData('tickets')
            await UseDeleteData('pending')
            generalApp.setActivePlaces = []
            userStore.testUser.place = ''
            generalApp.isAuthorized = false
            userStore.testUser.verified = false
            generalApp.customPlacesList = false
            generalApp.setShortPlace = false
            generalApp.nameRequest = {
                lastRequest: null,
                count: 0,
            }
        })
        generalApp.profileButton = false;
        navigateTo('/');
    } catch (e) {
        console.log(e, 'error during logout')
    }
}