import {useGeneralApp} from "~/stores/generalApp.js";
import {useNotifications} from "~/stores/notifications.js";
import {useUserStore} from "~/stores/user.js";
import {useGetAssignmentsList} from "~/composables/general/getAssignmentsList.js";

export const useNotificationList = async (message) => {
    await useGetAssignmentsList()

    const generalApp = useGeneralApp()
    const notifications = useNotifications()
    const userStore = useUserStore()

    const checkIfExist = () => {
        return (userStore.testUser.assigned_tickets.find((ticket) => Number(ticket.id) === Number(message.data.ticket_id))) ||
               (userStore.testUser.user_tickets.find((ticket) => Number(ticket.id) === Number(message.data.ticket_id)))
    }
    const setNotification =() => {
        let notificationData
        if (userStore.testUser.assigned_tickets.find((ticket) => Number(ticket.id) === Number(message.data.ticket_id))) {
            notificationData = {
                ticket_id: Number(message.data.ticket_id),
                event_type: message.data.eventType,
                path: `/assignments/${message.data.ticket_id}`,
                ticket_data: userStore.testUser.assigned_tickets.find((ticket) => Number(ticket.id) === Number(message.data.ticket_id))
            }
        } else {
            notificationData = {
                ticket_id: Number(message.data.ticket_id),
                event_type: message.data.eventType,
                path: `/my-tickets/ticket/${message.data.ticket_id}`,
                ticket_data: userStore.testUser.user_tickets.find((ticket) => Number(ticket.id) === Number(message.data.ticket_id))
            }
        }
        generalApp.currentListOfTicketNotifications.unshift(notificationData)
        notifications.listOfPushNotifications.unshift(notificationData)
        setTimeout(() => {
            notifications.listOfPushNotifications.splice(notifications.listOfPushNotifications.findIndex(notification => notification.ticket_id === Number(message.data.ticket_id)), 1)
        },5000)
    }
    if (checkIfExist()) {
        let notificationIndex = generalApp.currentListOfTicketNotifications.findIndex(notification => notification.ticket_id === Number(message.data.ticket_id))
        if (notificationIndex < 0) {
            setNotification()
        } else {
            generalApp.currentListOfTicketNotifications.splice(notificationIndex,1)
            setNotification()
        }
    }
}

