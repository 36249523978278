import {keys, get, del} from "idb-keyval";
import {useNotificationList} from "~/composables/notifications/handleNotificationList.js";


export const useNotificationFromIDB = async () => {
    try {
        let keysList = await keys()
        if (keysList.length > 0) {
            for (let key of keysList) {
                let message = await get(key)
                await useNotificationList(message)
                await del(key)
            }
        }
    } catch (err) {
        console.log(err, 'err getting notifications from idb')
    }
}