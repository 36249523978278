import { get } from 'idb-keyval'
import {UseKeysIDB} from "~/composables/keysIDB.js";

export const UseGetData = async (store, key) => {
    let data
    switch (store) {
        case 'app': {
            data = await get(key, UseKeysIDB().appData)
            break
        }
        case 'user': {
            data = await get(key, UseKeysIDB().userData)
            break
        }
        case 'tickets': {
            data = await get(key, UseKeysIDB().ticketsData)
            break
        }
        case 'pending': {
            data = await get(key, UseKeysIDB().pendingData)
            break
        }
        case 'assignments': {
            data = await get(key, UseKeysIDB().assignmentsData)
            break
        }
    }
    return data
}