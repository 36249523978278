import { keys } from 'idb-keyval'
import {UseKeysIDB} from "~/composables/keysIDB.js";
import {UseGetData} from "~/composables/getData.js";
import {UseUpdateData} from "~/composables/updateData.js";

export const useResetPendingStatus = async () => {
    try {
        let pendingKeys = await keys(UseKeysIDB().pendingData)
        for (let key of pendingKeys) {
            let data = await UseGetData('pending', key)
            if (data.in_work) {
                let newData = {
                    operationType: data.operationType,
                    in_work: false,
                    data: data.data
                }
                await UseUpdateData('pending', key, newData)            }
        }
    } catch (err) {
        console.log(err, 'error in reset pending status');
    }
}