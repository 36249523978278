import { update } from 'idb-keyval';
import {UseKeysIDB} from "~/composables/keysIDB.js";

export const UseUpdateData = async (store, key, newValue) => {
    try {
        switch (store) {
            case 'app': {
                await update(key, (val) => (newValue), UseKeysIDB().appData);
                break
            }
            case 'user': {
                await update(key, (val) => (newValue), UseKeysIDB().userData);
                break
            }
            case 'tickets': {
                await update(key, (val) => (newValue), UseKeysIDB().ticketsData);
                break
            }
            case 'pending': {
                await update(key, (val) => (newValue), UseKeysIDB().pendingData);
                break
            }
        }
    } catch (e) {
        console.log(e, 'error during update data');
    }
}