import {useUserStore} from "~/stores/user.js";


export const useSendFCMToken = async (token) => {
    const userStore = useUserStore()
    const config = useRuntimeConfig()
    try {
        let response = await fetch(`${config.public.apiBase}/firebase/store-device-fcm-token`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${userStore.token}`,
            },
            body: JSON.stringify({
                token: token
            })
        })
        if (!response.ok) {

        } else {
            console.log('FCM token attached')
        }
    } catch (err) {
        console.log(err, 'error sending FCM token')
    }
}