import { defineStore } from 'pinia'

export const useNotifications = defineStore('notifications', {
    state: () => ({
        eulAgreement: false,
        setWorkPlace: false,
        setUserName: false,
        wrongPhoneFormat: false,
        wrongPinCode: false,
        pinCodeOutdated: false,
        wrongConfirmData: false,
        networkError: false,
        leaveCreateForm: false,
        intensiveMarkRequiredFields: false,
        leaveCreateFromTo: '',
        leaveSettings: false,
        leaveCommentDraft: false,
        leaveCommentDraftTo: '',
        needCommentToChange: false,
        needCommentToChangeType: '',
        listOfPushNotifications: [],
        permissionDeniedNotification: false,
        permissionDeniedList: ''
    }),

    actions: {

    },
})