import { initializeApp, getApps } from 'firebase/app';
import { getMessaging, onMessage } from 'firebase/messaging';


export default defineNuxtPlugin(() => {
    const firebaseConfig = {
        apiKey: "AIzaSyBDWtRBV9aQNQYjXmYu5Fqafbqhk-8ym2M",
        authDomain: "vis-sc-mob.firebaseapp.com",
        projectId: "vis-sc-mob",
        storageBucket: "vis-sc-mob.appspot.com",
        messagingSenderId: "643922402905",
        appId: "1:643922402905:web:8b76bd9ac084b4189a9b48",
        measurementId: "G-DBL1KB6NV1"
    };
    const apps = getApps()
    const app = !apps.length ? initializeApp(firebaseConfig): apps[0]
    const messaging = getMessaging(app)
    return {
        provide: {
            messaging
        }
    }
})
