import { defineStore } from 'pinia'

export const useGeneralApp = defineStore('generalApp', {
    state: () => ({
        isAuthorized: false,
        requestProcessing: false,
        pending: false,
        confirmLogin: false,
        confirmRegistration: false,
        confirmCode: false,
        confirmPasswordReset: false,
        currentAction: '',
        backButton: false,
        profileButton: false,
        personalData: false,
        userPhone: '',
        confirmPin: ['','','',''],
        needPersonalData: true,
        retryGetCode: 1,
        lastGetCodeRequested: 0,
        showMedia: false,
        ticketDone: false,
        remainTime: 0,
        sorting: false,
        isOffline: false,
        offlineAdd: false,
        offlineNew: false,
        archiveActive: false,
        currentListOfTickets: [],
        currentListOfTicketNotifications: [],
        newName: '',
        newPlace: '',
        setShortPlace: false,
        setActivePlaces: [],
        tempPlaces: [],
        customPlacesList: false,
        sortingByStatus: 'none',
        sortingByDate: 'none',
        danger_category: [],
        places: [],
        priorities: [],
        statuses: [],
        resetTicketList: false,
        commentsDisplay: true,
        refreshTicketData: 0,
        openCommentMedia: null,
        scrollPositionTicketList: null,
        scrollPositionComments: null,
        currentTicket: null,
        currentTicketHasPending: false,
        replyComment: {
            reply: false,
            commentID: '',
            commentAuthorName: ''
        },
        commentsNotifications: true,
        needPersonalDataNotification: false,
        statusChangeNotifications: false,
        pushNotifications: true,
        smsNotifications: true,
        nameRequest: {
            lastRequest: null,
            count: 0,
        },
        audioRecording: false,
        ticketLoaded: false,
    }),

    actions: {

    },
    persist: {
        storage: persistedState.localStorage,
    },
})