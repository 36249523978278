import {useGeneralApp} from "~/stores/generalApp.js";

export const useUserNameNotification = () => {
    const generalApp = useGeneralApp()
    if (generalApp.nameRequest.count === 0) {
        generalApp.nameRequest.count = 1
        generalApp.nameRequest.lastRequest = new Date()
        return true
    } else if (generalApp.nameRequest.count === 1) {
        generalApp.nameRequest.count = 2
        generalApp.nameRequest.lastRequest = new Date()
        return true
    }
    else {
        console.log((new Date().getTime() - new Date(generalApp.nameRequest.lastRequest).getTime()) > 1000*60*60*24)
        if ((new Date().getTime() - new Date(generalApp.nameRequest.lastRequest).getTime()) > 1000*60*60*24) {
            switch (generalApp.nameRequest.count) {
                case 2: {
                    generalApp.nameRequest.count = 3
                    generalApp.nameRequest.lastRequest = new Date()
                    return true
                }
                case 3: {
                    generalApp.nameRequest.count = 4
                    generalApp.nameRequest.lastRequest = new Date()
                    return true
                }
                default: {
                    return false
                }
            }
        }
    }
}