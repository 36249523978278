import {useGeneralApp} from "~/stores/generalApp.js";
import {UseUpdateData} from "~/composables/updateData.js";
import {UseGetData} from "~/composables/getData.js";
import {useUserStore} from "~/stores/user.js";
import {useUpdateUserLogin} from "~/composables/general/updateUserLogin.js";
import {useHandleLogout} from "~/composables/general/handleLogout.js";

export const useGetUserInfo = async () => {
    const generalApp = useGeneralApp()
    const userStore = useUserStore()
    const config = useRuntimeConfig();
    const notAuthenticated = async (response) => {
        if (response.status === 401) {
            await useHandleLogout()
        }
    }
    try {
        let response = await fetch(`${config.public.apiBase}/users/info`, {
            method: "GET",
            headers: {
                contentType: "application/json",
                accept: "application/json",
                authorization: "Bearer " + userStore.token,
            }
        })
        if (response.status === 200) {
            let res = await response.json()
            await UseUpdateData('user', 'id', res.Data.user.id).then(async() => {
                userStore.testUser.id = await UseGetData('user', 'id')
            })
            await useUpdateUserLogin(res)
            console.log(res.Data.user.permissions.includes('EXECUTOR_USER_ROLE'))
            res.Data.user.permissions.includes('EXECUTOR_USER_ROLE')
            ?await UseUpdateData('user', 'role', 'EXECUTOR').then(async() => {
                    userStore.testUser.role = await UseGetData('user', 'role')
                })
            :await UseUpdateData('user', 'role', res.Data.user.role).then(async() => {
                    userStore.testUser.role = await UseGetData('user', 'role')
                })
        } else {
            await notAuthenticated(response)
        }
    } catch (err) {
        console.log(err, 'error getting user info')
    }
}