import {set} from 'idb-keyval'
import {UseKeysIDB} from "~/composables/keysIDB.js";
import {useGeneralApp} from "~/stores/generalApp.js";

export const UseCreatePendingData = async (id, operationType, data, in_work = false) => {
    try {
        const generalApp = useGeneralApp()
        switch (operationType) {
            case 'new_ticket': {
                console.log(data)
                let arr = data.media_attachments.splice(0, data.media_attachments.length);
                console.log(arr)
                set( `new_ticket/${id}`,  { operationType: operationType, in_work: in_work, data: {
                        id: `pending${data.id}`,
                        status: data.status,
                        number: '',
                        subject: ``,
                        date: data.date,
                        user_id: data.user_id,
                        emergency_type: data.emergency_type,
                        description: data.description,
                        danger_category: data.danger_category,
                        danger_zone: data.danger_zone,
                        danger_sub_zone: data.danger_sub_zone,
                        media_attachments: arr,
                        comment: data.comment,
                        notifications: true,
                    } }, UseKeysIDB().pendingData)
                    .then(() => console.log(`pending operation type: ${operationType} num.:${id} created`))
                    .catch((e) => console.log(e));
                break
            }
            case 'media_data': {
                console.log(data)
                set(`media_for/${id}`, { operationType: operationType, in_work: false, data: data}, UseKeysIDB().pendingData)
                    .then(() => {
                    console.log(`pending operation type: ${operationType} num.:${id} created`)})
                    .catch((e) => {
                        console.log(e)
                    })
                break
            }
            case "comment": {
                set(`comment_for/${id}`, { operationType: operationType, in_work: false, data: data}, UseKeysIDB().pendingData)
                    .then(() => {
                        console.log(`pending operation type: ${operationType} num.:${id} created`)})
                    .catch((e) => {
                        console.log(e)
                    })
                break
            }
            case "comment_media": {
                console.log(data)
                set(`media_for_comment/${id}`, { operationType: operationType, in_work: false, data: data}, UseKeysIDB().pendingData)
                    .then(() => {
                        console.log(`pending operation type: ${operationType} num.:${id} created`)})
                    .catch((e) => {
                        console.log(e)
                    })
                break
            }
            case "archive": {
                set(`archive/${id}`, { operationType: operationType, in_work: false, data: data}, UseKeysIDB().pendingData)
                    .then(() => {
                        console.log(`pending operation type: ${operationType} num.:${id} created`)})
                    .catch((e) => {
                        console.log(e)
                    })
                break
            }
        }
        generalApp.pending = true
    } catch (e) {
        console.log(e, 'error during create pending data')
    }
}