import {delMany, keys} from 'idb-keyval';
import {UseKeysIDB} from "~/composables/keysIDB.js";

export const UseDeleteData = async (store) => {
    switch (store) {
        case 'user': {
            let storeKeys = await keys(UseKeysIDB().userData)
            await delMany(storeKeys, UseKeysIDB().userData);
            break
        }
        case 'tickets': {
            let storeKeys = await keys(UseKeysIDB().ticketsData)
            await delMany(storeKeys,  UseKeysIDB().ticketsData);
            break
        }
        case 'pending': {
            let storeKeys = await keys(UseKeysIDB().pendingData)
            await delMany(storeKeys,  UseKeysIDB().pendingData);
            break
        }
    }
}
